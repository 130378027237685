const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  accountSettings:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("accountSettings"))
      : {},
  pages: [],
  selectedAccountPage: null,
  maintenanceMode: null,
};

const accountSlice = createSlice({
  name: "accountPages",
  initialState,
  reducers: {
    setAccountSettings: (state, action) => {
      state.accountSettings = action.payload;
      localStorage.setItem("accountSettings", JSON.stringify(action.payload));
    },
    setSelectedAccountPage: (state, action) => {
      state.selectedAccountPage = action.payload;
    },
    setAccountPages: (state, action) => {
      state.pages = action.payload;
      state.selectedAccountPage = action.payload[0];
    },
    setMaintenanceMode: (state, action) => {
      state.maintenanceMode = action.payload;
    },
  },
});

export const {
  setSelectedAccountPage,
  setAccountPages,
  setAccountSettings,
  setMaintenanceMode,
} = accountSlice.actions;
export default accountSlice.reducer;
