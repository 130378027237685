import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
  selectedBranch: typeof window !== "undefined" ? JSON.parse(localStorage.getItem("branch")) : null,
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    updateBranch: (state, action) => {
      state.selectedBranch = action.payload;

      localStorage.setItem("branch", JSON.stringify(state.selectedBranch));
    },
  },
});

export const { updateBranch } = branchSlice.actions;
export default branchSlice.reducer;
