import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("cart")) || []
      : [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const offerId = newItem.order_details[0].offer_id;
      const productId = newItem.order_details[0].account_product_detail_id;
      const selectedPrice = newItem.order_details[0].unit_price;

      // Check if an item with the same `productId` and `selectedPrice` exists
      const existingItemIndex = state.cart.findIndex((item) => {
        return item.order_details.some(
          (orderDetail) =>
            (orderDetail.offer_id && orderDetail.offer_id === offerId) ||
            (orderDetail.account_product_detail_id &&
              orderDetail.account_product_detail_id === productId &&
              orderDetail.unit_price === selectedPrice) // Match price too
        );
      });

      if (existingItemIndex !== -1) {
        const existingItem = state.cart[existingItemIndex];
        const orderDetail = existingItem.order_details.find(
          (detail) =>
            (detail.offer_id && detail.offer_id === offerId) ||
            (detail.account_product_detail_id &&
              detail.account_product_detail_id === productId &&
              detail.unit_price === selectedPrice) // Match price too
        );
        orderDetail.quantity += 1;
        orderDetail.quantity_total +=
          newItem.order_details[0].unit_quantity_total;
      } else {
        const newCartItem = {
          order_details: newItem.order_details.map((detail) => ({
            ...detail,
            quantity: 1,
            quantity_total: detail.unit_quantity_total,
            unit_quantity_total: detail.unit_quantity_total,
          })),
        };
        state.cart.push(newCartItem);
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => {
        const itemId =
          item.offer_id || item.order_details?.[0]?.account_product_detail_id;
        return itemId !== action.payload;
      });

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    clearCart: (state) => {
      state.cart = [];
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    incrementQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        const itemId =
          item.offer_id || item.order_details?.[0]?.account_product_detail_id;

        if (itemId === action.payload) {
          if (item.order_details) {
            return {
              ...item,
              order_details: [
                {
                  ...item.order_details[0],
                  quantity: item.order_details[0].quantity + 1,
                  quantity_total:
                    item.order_details[0].quantity_total +
                    item.order_details[0].unit_quantity_total,
                },
              ],
            };
          } else if (item.offer_id) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
        }
        return item;
      });
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    decrementQuantity: (state, action) => {
      const itemIndex = state.cart.findIndex((item) => {
        const itemId =
          item.offer_id || item.order_details?.[0]?.account_product_detail_id;
        return itemId === action.payload;
      });

      if (itemIndex !== -1) {
        if (state.cart[itemIndex].order_details) {
          const currentQuantity =
            state.cart[itemIndex].order_details[0].quantity;

          if (currentQuantity > 1) {
            state.cart[itemIndex].order_details[0].quantity -= 1;
            state.cart[itemIndex].order_details[0].quantity_total -=
              state.cart[itemIndex].order_details[0].unit_quantity_total;
          } else {
            state.cart.splice(itemIndex, 1);
          }
        } else if (state.cart[itemIndex].offer_id) {
          const currentQuantity = state.cart[itemIndex].quantity;

          if (currentQuantity > 1) {
            state.cart[itemIndex].quantity -= 1;
          } else {
            state.cart.splice(itemIndex, 1);
          }
        }

        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  incrementQuantity,
  decrementQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
