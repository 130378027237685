import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [],
  selectedOffer: null,
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    setSelectedOffer: (state, action) => {
      state.selectedOffer = action.payload;
    },
    resetSelectedOffer: (state) => {
      state.selectedOffer = null;
    },
    resetOffers: (state) => {
      state.offers = [];
    },
  },
});

export const { setOffers, setSelectedOffer, resetSelectedOffer, resetOffers } =
  offersSlice.actions;
export default offersSlice.reducer;
