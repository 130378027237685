const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  selectedCard: null,
  cards: [],
};

const paymentCardsSlice = createSlice({
  name: "paymentCards",
  initialState,
  reducers: {
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },

    appendCards: (state, action) => {
      state.cards = [...state.cards, action.payload];
    },
  },
});

export const { setSelectedCard, setCards, appendCards } =
  paymentCardsSlice.actions;
export default paymentCardsSlice.reducer;
