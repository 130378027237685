import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  user_information: null,
  coupon_code: null,
  order_time: null,
  address_id: null,
  branch_id: null,
  order_details: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setUserInformation(state, action) {
      state.user_information = action.payload;
    },
    setOrderTime(state, action) {
      state.order_time = action.payload;
    },
    setBranchId(state, action) {
      state.branch_id = action.payload;
    },
    setAddressId(state, action) {
      state.address_id = action.payload;
    },
    replaceOrderDetails(state, action) {
      state.order_details = action.payload;
    },
    setCouponCode(state, action) {
      state.coupon_code = action.payload;
    },

    setOrderDetail(state, action) {
      const { index, detail } = action.payload;
      state.order_details[index] = detail;
    },

    setOrderDetailsOptions(state, action) {
      const { index, options } = action.payload;
      state.order_details[index].order_details_options = options;
    },
  },
});

export const {
  setTotal,
  setUserInformation,
  setOrderTime,
  setBranchId,
  replaceOrderDetails,
  setOrderDetail,
  setAddressId,
  setOrderDetailsOptions,
  setCouponCode,
} = orderSlice.actions;

export default orderSlice.reducer;
