import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSection: "account",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setSection: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
});

export const { setSection } = profileSlice.actions;
export default profileSlice.reducer;
