import {
  setSecureItem,
  getSecureItem,
  removeSecureItem,
} from "@/Utils/secureStorage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "./loadingSlice";

export const fetchUserFromStorage = createAsyncThunk(
  "auth/fetchUserFromStorage",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const userData = await getSecureItem("user");
    dispatch(setLoading(false));

    if (userData && userData !== "null") {
      return userData;
    }
    return null;
  }
);

const initialState = {
  isAuth: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData(state, action) {
      state.isAuth = Boolean(action.payload);
      state.user = action.payload.user;
      setSecureItem("user", state.user);
    },
    removeUserData(state) {
      state.isAuth = false;
      state.user = null;
      removeSecureItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserFromStorage.fulfilled, (state, action) => {
        if (action.payload) {
          state.isAuth = true;
          state.user = action.payload?.data;
        } else {
          state.isAuth = false;
          state.user = null;
        }
      })
      .addCase(fetchUserFromStorage.rejected, (state) => {
        state.isAuth = false;
        state.user = null;
      });
  },
});

export const { logIn, removeUserData, setUserData } = authSlice.actions;
export default authSlice.reducer;
