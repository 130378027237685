import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sections: [],
  selectedSection: null,
  selectedStartTime: null,
  selectedEndTime: null,
  selectedDate: null,
  selectedTable: null,
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setSelectedStartTime: (state, action) => {
      state.selectedStartTime = action.payload;
    },
    setSelectedEndTime: (state, action) => {
      state.selectedEndTime = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedTable: (state, action) => {
      state.selectedTable = action.payload;
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getSections.fulfilled, (state, action) => {
  //       state.sections = action.payload;
  //       if (!state.selectedSection && action.payload.length) {
  //         state.selectedSection = action.payload[0];
  //       }
  //     })
  //     .addCase(getSections.rejected, (state) => {
  //       state.sections = [];
  //     });
  // },
});

export const {
  setSections,
  setSelectedSection,
  setSelectedStartTime,
  setSelectedEndTime,
  setSelectedDate,
  setSelectedTable,
} = sectionSlice.actions;
export default sectionSlice.reducer;
