import { store } from "@/redux/store";

export const getSubdomain = () => {
  const host = window.location.host;
  const parts = host.split(".");

  if (process.env.NODE_ENV !== "production") {
    return "kfc";
  }

  if (
    parts.length > 2 &&
    !parts[0].includes(
      "1" || "2" || "3" || "4" || "5" || "6" || "7" || "8" || "9" || "0"
    )
  ) {
    return parts[0];
  }

  return null;
};

export const currencyFormat = () => {
  return store.getState().language === "ar" ? "ريال" : "SAR";
};
