import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState:
    typeof window !== "undefined"
      ? localStorage.getItem("language") || "ar"
      : "ar",
  reducers: {
    setReduxLanguage: (state, action) => action.payload,
  },
});

export const { setReduxLanguage } = languageSlice.actions;
export default languageSlice.reducer;
