import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedbacks: [],
  activeFeedbackId: null,
  currentQuestionIndex: -1,
};

const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    setFeedbacks: (state, action) => {
      state.feedbacks = action.payload;
    },
    setActiveFeedbackId: (state, action) => {
      state.activeFeedbackId = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
  },
});

export const { setFeedbacks, setActiveFeedbackId, setCurrentQuestionIndex } =
  feedbacksSlice.actions;

export default feedbacksSlice.reducer;
