import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoriteProducts: [],
};

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    toggleFavorite: (state, action) => {
      const productId = action.payload;
      if (state.favoriteProducts.includes(productId)) {
        state.favoriteProducts = state.favoriteProducts.filter(id => id !== productId);
      } else {
        state.favoriteProducts.push(productId);
      }
    },
    setFavoritesFromProducts: (state, action) => {
      state.favoriteProducts = action.payload;
    },
  },
});

export const { toggleFavorite, setFavoritesFromProducts } = favoriteSlice.actions;

export default favoriteSlice.reducer;
