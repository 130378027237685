import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import loadingReducer from "./slices/loadingSlice";
import productReducer from "./slices/productSlice";
import branchReducer from "./slices/branchSlice";
import addressReducer from "./slices/addressSlice";
import cartReducer from "./slices/cartSlice";
import orderReducer from "./slices/orderSlice";
import favoriteReducer from "./slices/favoritesSlice";
import sectionsReducer from "./slices/sectionsSlice";
import offersReducer from "./slices/offersSlice";
import profileReducer from "./slices/profileSlice";
import UserAccountReducer from "./slices/userAccountSlice";
import feedbacksReducer from "./slices/feedbacksSlice";
import paymentCardsReducer from "./slices/paymentCardsSlice";
import accountPagesReducer from "./slices/accountSlice";
import languageReducer from "./slices/languageSlice";

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: authReducer,
    product: productReducer,
    branch: branchReducer,
    address: addressReducer,
    cart: cartReducer,
    order: orderReducer,
    favorite: favoriteReducer,
    sections: sectionsReducer,
    offers: offersReducer,
    profile: profileReducer,
    userAccount: UserAccountReducer,
    feedbacks: feedbacksReducer,
    paymentCards: paymentCardsReducer,
    accountPages: accountPagesReducer,
    language: languageReducer,
  },
});
