import axios from "axios";
import { setLoading } from "@/redux/slices/loadingSlice";
import { store } from "@/redux/store";
import { getSubdomain } from "@/Utils/commons";
import { setMaintenanceMode } from "@/redux/slices/accountSlice";
import { clearCart } from "@/redux/slices/cartSlice";

// Define response handlers
const responseHandlers = {
  200: (response) => response,
  201: (response) => response,
  204: (response) => {
    console.log("No content.");
  },
  401: (response) => {
    console.log("Unauthorized access.");
    return response;
  },
  403: (response) => {
    console.log("Forbidden access.");
    return response;
  },
  409: (response) => response,
  422: (response) => response,
  500: (response) => {
    console.log("Server error, please try again later.");
    return response;
  },
  503: (response) => {
    console.log("Server error, please try again later.");
    return response;
  },
};

// Handle the response based on status code
const handleResponse = (response) => {
  const handler = responseHandlers[response.status];

  if (handler) {
    return handler(response);
  } else {
    const errorResponse = response.data;
    const message =
      errorResponse.error || errorResponse.message || "Unknown error occurred";
    throw new Error(message);
  }
};

// Build the base URL
const buildBaseUrl = (endPoint) => {
  const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
  let subDomain = getSubdomain();
  if (!subDomain) {
    throw new Error("Subdomain not found");
  }
  const url = `https://${subDomain}.${baseURL}${endPoint}`;

  return url;
};

// Build HTTP request function with setIsLoading passed as an argument
export const buildHttpResponse = async ({
  method = "GET",
  endPoint,
  headers = {},
  body = null,
  // setIsLoading, // Pass the setIsLoading from the component
}) => {
  try {
    store.dispatch(setLoading(true));

    const url = buildBaseUrl(`${endPoint}`);
    console.log(url);

    const config = {
      url,
      method,
      headers: {
        "Content-Type": "application/json",
        "X-Language": store.getState().language,
        ...headers,
      },
      data: body,
    };

    const response = await axios(config);

    return handleResponse(response);
  } catch (error) {
    if (error.response?.status === 403) {
      store.dispatch(clearCart());
      store.dispatch(setMaintenanceMode(error.response.data));
    }
    throw error.response?.data || error;
  } finally {
    store.dispatch(setLoading(false));
  }
};
