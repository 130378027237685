import { buildHttpResponse } from "@/network/networkUtils";
import { removeSecureItem, setSecureItem } from "@/Utils/secureStorage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";

export const updateUserProfile = createAsyncThunk(
  "user/updateProfile",
  async (formData) => {
    const response = await buildHttpResponse({
      method: "POST",
      endPoint: "update-Profile",
      body: formData,
      headers: {
        Authorization: `Bearer ${store.getState().auth.user?.token}`,
        // Authorization: `Bearer 408|RHXTK25wHwM2Mduhn4t8hUPdNqrKpDhfuoQjzP3Tf4ed40d1`,
      },
    });

    if (response.data.success) {
      await removeSecureItem("user");
      setSecureItem("user", response.data.result);
      return response.data.result;
    } else {
      console.log(response.data);

      throw new Error(response.data);
    }
  }
);

const userAccountSlice = createSlice({
  name: "userAccount",
  initialState: {
    user: null,
    error: null,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const { setUser } = userAccountSlice.actions;
export default userAccountSlice.reducer;
