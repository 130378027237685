import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addresses: [],
  selectedAddress: null,
  addressType: "dine-in",
  withoutDelivery: false,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    addAddress: (state, action) => {
      state.addresses.push(action.payload);
    },
    updateSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    setAddressType: (state, action) => {
      state.addressType = action.payload;
    },
    setWithoutDelivery: (state, action) => {
      state.withoutDelivery = action.payload;
    },
    clearAddresses: (state) => {
      state.addresses = [];
    },
  },
});

export const {
  addAddress,
  updateSelectedAddress,
  setAddressType,
  setWithoutDelivery,
  clearAddresses,
} = addressSlice.actions;
export default addressSlice.reducer;
