// import { productDetailsService } from "@/services/productServices";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

// export const fetchProductDetails = createAsyncThunk(
//   "product/fetchProductDetails",
//   async (productId) => {
//     const product = await productDetailsService(productId);
//     if (product) {
//       return product;
//     } else {
//       return null;
//     }
//   }
// );

const initialState = {
  products: [],
  currentPage: 1,
  lastPage: false,
  selectedProduct: null,
  showPopup: false,
  totalProducts: 0,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct(state, action) {
      state.selectedProduct = action.payload;
      state.showPopup = true;
    },
    clearProduct(state) {
      state.selectedProduct = null;
      state.showPopup = false;
    },
    closePopup(state) {
      state.showPopup = false;
    },

    appendProducts(state, action) {
      state.products = [...state.products, ...action.payload.products];
      state.currentPage = action.payload.currentPage;
      state.lastPage = action.payload.lastPage;
      state.totalProducts = action.payload.totalProducts;
    },

    resetProducts(state) {
      state.products = [];
      state.currentPage = 1;
      state.lastPage = false;
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchProductDetails.fulfilled, (state, action) => {
  //       state.showPopup = true;
  //       state.selectedProduct = action.payload;
  //     })
  //     .addCase(fetchProductDetails.rejected, (state) => {
  //       state.selectedProduct = null;
  //     });
  // },
});

export const {
  setProduct,
  clearProduct,
  closePopup,
  appendProducts,
  resetProducts,
} = productSlice.actions;

export default productSlice.reducer;
