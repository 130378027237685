import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY;

const encrypt = (data) => CryptoJS.AES.encrypt(data, SECRET_KEY).toString();

const decrypt = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};

export const setSecureItem = (key, data) => {
  const userData = { data };
  const expires = 30; // 30 days

  const encryptedData = encrypt(JSON.stringify(userData));

  Cookies.set(key, encryptedData, {
    expires,
    secure: process.env.NODE_ENV === "production",
    sameSite: "Strict",
  });
};

export const getSecureItem = (key) => {
  const encryptedData = Cookies.get(key);
  if (!encryptedData) return null;

  const decryptedData = decrypt(encryptedData);
  return decryptedData ? JSON.parse(decryptedData) : null;
};

export const removeSecureItem = (key) => {
  Cookies.remove(key);
};
